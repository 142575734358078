import React from "react"
import {About} from "../components/pages/About"
import { Seo } from "../components/seo"

const AboutJp = () => {
  return <About language={"jp"}></About>
}

export default AboutJp

export const Head = () => {
  return (
    <Seo
      title="会社概要"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/about`}
      image="https://res.cloudinary.com/dkskzhwoe/image/upload/v1738628213/919mexico_thumbnail_228c04bd38.png"
    />
  )
}
