import React, { useEffect, useState } from "react"
// Components
import { SectionTitle } from "../styled"
import WrapperRight from "../WrapperRight"
import Contact from "../Contact"
import { Seo } from "../seo"
import { languageToTranlate } from "../../funtions/language"
import img1 from "../../images/company-group/01.webp"
import img2 from "../../images/company-group/02.webp"
import img3 from "../../images/company-group/03.webp"
import img4 from "../../images/company-group/04.webp"
import img5 from "../../images/company-group/05.webp"
import img6 from "../../images/company-group/06.webp"
import img8 from "../../images/company-group/08.webp"
import img9 from "../../images/company-group/09.webp"
import img10 from "../../images/company-group/10.webp"
import img11 from "../../images/company-group/11.webp"
import img12 from "../../images/company-group/12.webp"
import img13 from "../../images/company-group/13.webp"
import img14 from "../../images/company-group/14.webp"
import logl from "../../images/about/location-icon.png"
import logp from "../../images/about/phone-icon.png"
import loge from "../../images/about/email-icon.png"
import logf from "../../images/about/fax-icon.png"

export const About = ({ language }) => {
  const companyLogo = [
    { link: "https://www.919usa.com/default.aspx", image: img1 },
    { link: "https://919.jp/", image: img2 },
    { link: "https://www.colorfulcompany.co.jp/", image: img3 },
    { link: "http://www.career-system.net/", image: img4 },
    { link: "https://www.workproject.jp/", image: img5 },
    { link: "https://hrvision.co.jp/", image: img6 },
    { link: "https://jumpers.jp/", image: img8 },
    { link: "http://919sh.cn/", image: img9 },
    { link: "nolink", image: img10 },
    { link: "https://919vn.com/", image: img11 },
    { link: "https://www.centrepeople.com/", image: img12 },
    { link: "nolink", image: img13 },
    { link: " https://www.qhr.co.th/ja/", image: img14 },
  ]

  const [lang, setLang] = useState("")
  useEffect(() => {
    setLang(languageToTranlate(language))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="container about">
      <div className="row">
        <div className="col-lg-9 col-lg-9">
          <div className="about__company-profile">
            <SectionTitle>{lang["about.title"]}</SectionTitle>
            <hr className="u-line-bottom" />
            <p>{lang["about.titleDescription"]}</p>
            <h2>{lang["about.subtitle1"]}</h2>
            <p>
              <b>{lang["about.text11"]}</b>
              <br />
              {lang["about.text12"]}
              <br />
              {lang["about.text13"]}
            </p>
            <h2>{lang["about.subtitle2"]}</h2>
            <p>
              <b>{lang["about.text21"]}</b>
              <br />
              {lang["about.text22"]}
              <br />
              {lang["about.text23"]}
            </p>
            <h2>{lang["about.subtitle3"]}</h2>
            <p>
              {lang["about.text31"]}
              <br />
              {lang["about.text32"]}
            </p>
            <h2>{lang["about.subtitle4"]}</h2>
            <h3>
              <b>
                QUICK GLOBAL MEXICO, S.A. DE C.V. Aguascalientes Office（Head
                Office）
              </b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.333403487488!2d-102.29335058555849!3d21.921730662070644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429efadbb8daf29%3A0xcea673564e790a2b!2sCentro%20Comercial%20Punto%2045!5e0!3m2!1sen!2sbo!4v1646328779092!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Aguascalientes Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      C.C. Punto 45, Boulevard A Zacatecas 845, Oficina 510,
                      Desarrollo Especial Bulevar a Zacatecas, C.P.20116
                      Aguascalientes, Ags.
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      +52-449-107-5308
                    </p>
                  </div>

                  <div>
                    <p className="about__columns__details__subtitle">E-mail</p>
                    <hr className="class-hr" />
                    <div className="about__columns_col">
                      <img src={loge} alt="" />
                      <p className="about__columns__details__text">
                        quick@919mexico.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3>
              <b>QUICK GLOBAL MEXICO, S.A. DE C.V. Querétaro Office</b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.3668695399037!2d-100.36143041534017!3d20.57307021372023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d344bcafacc4bf%3A0xf7471c51ba04636e!2sAv.%20Armando%20Birlain%20Shaffler%202001-Piso%2015A%2C%20Centro%20Sur%2C%2076090%20Santiago%20de%20Quer%C3%A9taro%2C%20Qro.%2C%20Mexico!5e0!3m2!1sen!2sbo!4v1691690459397!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Querétaro Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      Av. Armando Birlain 2001 Corporativo 1 Piso 15A <br />
                      Colonia Centro Sur（Complejo Central Park） <br />
                      Santiago de Querétaro, Qro. C.P 76090 <br />
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      449‐111-8229
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">E-mail</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={loge} alt="" />
                    <p className="about__columns__details__text">
                      quick@919mexico.com
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="dots"></div>
            <h2 style={{ border: "none" }}>{lang["about.aboutUsCompany"]}</h2>
            <h3>
              <b>QUICK USA, Inc. New York Office</b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.4951138529004!2d-73.98578848507877!3d40.75113384315832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259aa0e11bc47%3A0x54d5e456471a4425!2s8%20W%2038th%20St%2C%20New%20York%2C%20NY%2010018%2C%20USA!5e0!3m2!1sen!2sbo!4v1646349059302!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="New York Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      8 West 38th Street, Suite 802, New York, NY 10018 USA
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      1-212-692-0850
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Fax</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logf} alt="" />
                    <p className="about__columns__details__text">
                      1-212-692-0853
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">E-mail</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={loge} alt="" />
                    <p className="about__columns__details__text">
                      quick@919usa.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h3>
              <b>QUICK USA, Inc. Los Angeles Office</b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.1666718416104!2d-118.31518408528915!3d33.859595235305996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b544ec7f8697%3A0x83774cbfc00f0892!2s1995%20W%20190th%20St%2C%20Torrance%2C%20CA%2090504%2C%20USA!5e0!3m2!1sen!2sbo!4v1646349104222!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Los Angeles Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      1995 W.190th Street, Suite 200 Torrance, CA 90504 USA
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      1-310-323-9190
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Fax</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logf} alt="" />
                    <p className="about__columns__details__text">
                      1-310-323-9166
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">E-mail</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={loge} alt="" />
                    <p className="about__columns__details__text">
                      quickla@919usa.com
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h3>
              <b>QUICK USA, Inc. Orange County Office</b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6641.634696359495!2d-117.8691024!3d33.6618943!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcde5a390fffff%3A0x11f7b99abecf6bf9!2s4041%20MacArthur%20Blvd%20Suite%20400%2C%20Newport%20Beach%2C%20CA%2092660%2C%20USA!5e0!3m2!1sen!2sbo!4v1691690830308!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Orange County Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      4041 MacArthur Blvd Suite 400, Newport Beach, CA 92660
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      010-1-310-722-3813
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">E-mail</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={loge} alt="" />
                    <p className="about__columns__details__text">
                      quick@919mexico.com
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h3>
              <b>QUICK USA, Inc. Dallas Office</b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3342.6930064536227!2d-96.82120498919572!3d33.09086027342371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c3cbe98c69cf1%3A0x9f7c52a2e9d9e1d6!2s5525%20Granite%20Pkwy%2C%20Plano%2C%20TX%2075024%2C%20USA!5e0!3m2!1sen!2sbo!4v1739220799563!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Dallas Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      5525 Granite Parkway, Suite 740, Plano, Texas 75024
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      +1-214-293-4874
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">E-mail</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={loge} alt="" />
                    <p className="about__columns__details__text">
                      quicktx@919usa.com
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h3>
              <b>QUICK USA, Inc. Chicago Office</b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5927.627113590355!2d-88.0374607!3d42.0257319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fafb5c1c21da9%3A0x423a5c67706d0187!2s10%20N%20Martingale%20Rd%20%23400%2C%20Schaumburg%2C%20IL%2060173%2C%20USA!5e0!3m2!1sen!2sbo!4v1691690654157!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Chicago Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      10 N. Martingale Road Suite 400, Schaumburg, IL 60173 USA
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      1-646-796-6393
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">E-mail</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={loge} alt="" />
                    <p className="about__columns__details__text">
                      quickil@919usa.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h3>
              <b>QUICK USA, Inc. Detroit Office</b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2944.260635361001!2d-83.4374247!3d42.4434703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824ae0a26ecf98d%3A0xaf8784716915eb8!2sCrystal%20Glen%20Office%20Centre!5e0!3m2!1sen!2sbo!4v1719876621904!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Detroit Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      39555 Orchard Hill Place Crystal Glen, # 600, Novi, MI
                      48375
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      847-791-2504
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">E-mail</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={loge} alt="" />
                    <p className="about__columns__details__text">
                      quickil@919usa.com
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h3>
              <b>QUICK USA, Inc. Atlanta Office</b>
            </h3>
            <div className="about__columns">
              <div className="about__columns__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3310.476290298295!2d-84.3348291!3d33.928875999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5095e76ab40d9%3A0x520cbd3208d45bc3!2s303%20Perimeter%20Center%20N%20%23300%2C%20Atlanta%2C%20GA%2030346%2C%20USA!5e0!3m2!1sen!2sbo!4v1691690556007!5m2!1sen!2sbo"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  loading="lazy"
                  title="Atlanta Office"
                ></iframe>
              </div>
              <div className="about__columns__details">
                <div>
                  <p className="about__columns__details__subtitle1">Address</p>
                  <hr className="class-hr" />
                  <div
                    className="about__columns_col"
                    style={{ alignItems: "flex-start" }}
                  >
                    <img src={logl} alt="" />
                    <p className="about__columns__details__text">
                      303 Perimeter Center N Ste. 300, Atlanta, GA 30346
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">Phone</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={logp} alt="" />
                    <p className="about__columns__details__text">
                      1-404-706-5266
                    </p>
                  </div>
                </div>
                <div>
                  <p className="about__columns__details__subtitle">E-mail</p>
                  <hr className="class-hr" />
                  <div className="about__columns_col">
                    <img src={loge} alt="logousa" />
                    <p className="about__columns__details__text">
                      quickga@919usa.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2>{lang["about.titleCompanie"]}</h2>
          <div className="container-full u-margin-bottom-2 logos">
            <div className="row">
              {companyLogo.map((logo, i) => (
                <div className="col-lg-4 u-margin-top-1-5 u-margin-bottom-1-5">
                  {logo.link === "nolink" ? (
                    <img src={logo.image} alt={"logocompany-nolink" + i} />
                  ) : (
                    <a href={logo.link} target="_blank" rel="noreferrer">
                      <img src={logo.image} alt={"logocompany-link" + i} />
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
          <Contact lang={"ja"} />
        </div>
        <WrapperRight />
      </div>
    </section>
  )
}
